import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import { FC } from 'react';

import {
  encodeImageSrc,
  getCarmaGenericImageLoader,
  hasImageLoader,
} from 'src/general/helpers/carmaGenericImageLoader';
import { NextImage } from './NextImage';

type Props = {
  src: string | StaticImport;
  alt: string;
  width: number;
  height: number;
  priority?: boolean;
  className?: string;
  'data-testid'?: string;
};

/**
 * Wrapper around NextJS Image component to allow dynamic selection of the image loader
 */
export const Image: FC<Props> = ({ alt, priority, ...props }) => {
  if (typeof props.src === 'string' && (hasImageLoader(props.src) || isSvg(props.src))) {
    const encodedSrc = encodeImageSrc(props.src);
    const srcSet = generateSrcSet(encodedSrc, props.width);

    return (
      <img
        {...props}
        alt={alt}
        src={srcSet[0]}
        srcSet={srcSet[0] !== srcSet[1] ? srcSet[1] : undefined}
        decoding="async"
        fetchPriority={priority ? 'high' : undefined}
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  // Defaults to NextJS Image component
  return <NextImage {...props} alt={alt} priority={priority} />;
};

const generateSrcSet = (src: string, width: number) => {
  if (isSvg(src)) {
    return [src, src];
  }
  const loader = getCarmaGenericImageLoader(src)!;
  const image1X = loader({ src: src, width });
  const image2X = loader({ src: src, width: width * 2 });

  return [image1X, `${image1X} 1x, ${image2X} 2x`];
};

const isSvg = (src: string) => src.endsWith('.svg');
